import { HttpClient } from '@angular/common/http';
import { Injectable, inject, signal } from '@angular/core';
import { environment } from '@core/environments/environment';
import {
  ActivateAccount,
  ForgotPassword,
  ForgotPasswordResponse,
  Login,
  LoginResponse,
  CreateProfile,
  ResetPassword,
  ResetPasswordResponse,
  UpdatePassword,
  CreateProfileResponse,
  ActivateAccountResponse,
  CreateTransactionPin,
  CreateTransactionPinResponse,
} from '@core/models/client/auth';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  http = inject(HttpClient);
  BASE_URL = environment.CLIENT_BASE_URL + '/auth';

  // currentUserSignal = signal<undefined | null>(undefined);
  // currentUserTokenSignal = signal<string>('');

  createProfile(profileInfo: CreateProfile): Observable<CreateProfileResponse> {
    return this.http.post<CreateProfileResponse>(
      `${this.BASE_URL}/signup`,
      profileInfo
    );
  }

  activateAccount(otp: ActivateAccount): Observable<ActivateAccountResponse> {

    const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      ...otp, // Spread the existing otp object
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<ActivateAccountResponse>(

      `${this.BASE_URL}/activate-account`,
      requestBody
    );
  }

  resendOtp(): Observable<{ status: string; message: string }> {
      const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<{ status: string; message: string }>(
      `${this.BASE_URL}/resend-otp`,
      requestBody
    );
  }

  login(userLoginInfo: Login): Observable<LoginResponse> {
    return this.http.post<LoginResponse>(
      `${this.BASE_URL}/login`,
      userLoginInfo
    );
  }

  createTransactionPin(
    otp: CreateTransactionPin
  ): Observable<CreateTransactionPinResponse> {
    const AUTH_TOKEN = localStorage.getItem('signupToken');

    // Merge the token into the body along with the otp
    const requestBody = {
      ...otp, // Spread the existing otp object
      token: AUTH_TOKEN // Add the token to the body
    };
    return this.http.post<CreateTransactionPinResponse>(
      `${this.BASE_URL}/create-pin`,
      requestBody
    );
  }

  forgotPassword(
    userEmailInfo: ForgotPassword
  ): Observable<ForgotPasswordResponse> {
    const token = localStorage.getItem('token');
    return this.http.post<ForgotPasswordResponse>(
      `${this.BASE_URL}/forget-password`,
      userEmailInfo
    );
  }

  resetPassword(
    resetPasswordInfo: ResetPassword
  ): Observable<ResetPasswordResponse> {
    return this.http.post<ResetPasswordResponse>(
      `${this.BASE_URL}/reset-password`,
      resetPasswordInfo
    );
  }

  updatePassword(updatePasswordInfo: UpdatePassword) {
    return this.http.post(this.BASE_URL, updatePasswordInfo);
  }
}
